.tituloProyecto{
    font-size: 24px;
}

.caracteristicasGenerales{
    height: 80px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
}

.descripcion{
    bottom: -30px;
}  

.direccion{
    font-size: 9px;
}

.departamentosVillas .btnSelector:hover{
    transform: scale(1.2);
}

.departamentosVillas .btnSelector{
    transition: all 0.5s;
    font-size: 20px;
}

.departamentosVillas .btnSelector.zoomed{
    text-shadow: 4px 5px 6px rgba(0, 0, 0, 0.37);
    transform: scale(1.2);
} 

.departamentosVillas th{
    font-size: 13px;
}

.departamentosVillas td{
    font-size: 12px;
}

@media (min-width: 576px) { 
    .tituloProyecto{
        font-size: 32px;
    }
    .caracteristicasGenerales{
        height: 70px;
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
    }
    .descripcion{
        bottom: 5px;
    }
    .direccion{
        font-size: 10px;
    } 
    .departamentosVillas .btnSelector{
        font-size: 19px;
    }
    .departamentosVillas .btnSelector.zoomed{
        transform: scale(1.2);
    }
    .tituloActividadesPorSemana{
        font-size: 19px;
    } 
    .contenedorImgReporte{
        height: 330px;
    }
    .departamentosVillas th{
        font-size: 15px;
    }    
    .departamentosVillas td{
        font-size: 14px;
    }
 }

@media (min-width: 768px) { 
    .tituloProyecto{
        font-size: 40px;
    }
    .caracteristicasGenerales{
        height: 90px;
        font-size: 14px;
        border-radius: 20px;
    }
    .descripcion{
        bottom: 10px;
    }  
    .direccion{
        font-size: inherit;
    }  
    .departamentosVillas .btnSelector{
        font-size: 23px;
    }
    .departamentosVillas .btnSelector.zoomed{
        transform: scale(1.3);
    }
    .tituloActividadesPorSemana{
        font-size: 14px;
    } 
    .contenedorImgReporte{
        height: 250px;
    }
 }

@media (min-width: 992px) { 
    .caracteristicasGenerales{
        height: 90px;
        font-size: 14px;
    }
    .descripcion{
        bottom: 10px;
    }
    .direccion{
        font-size: inherit;
    }
    .departamentosVillas .btnSelector{
        font-size: 24px;
    }
    .departamentosVillas .btnSelector.zoomed{
        transform: scale(1.2);
    }
    .tituloActividadesPorSemana{
        font-size: 18px;
    } 
    .contenedorImgReporte{
        height: 290px;
    }
 }

@media (min-width: 1200px) { 
    .tituloProyecto{
        font-size: 50px;
    }
    .caracteristicasGenerales{
        height: 120px;
        font-size: 16px;
    }
    .descripcion{
        bottom: 20px;
    }
    .direccion{
        font-size: inherit;
    }
    .departamentosVillas .btnSelector{
        font-size: 28px;
    }
    .departamentosVillas .btnSelector.zoomed{
        transform: scale(1.3);
    } 
    .tituloActividadesPorSemana{
        font-size: 18px;
    }
    .contenedorImgReporte{
        height: 250px;
    }
 }

@media (min-width: 1400px) { 
    .tituloProyecto{
        font-size: 60px;
    }
    .caracteristicasGenerales{
        height: 150px;
        font-size: 20px;
    }
    .direccion{
        font-size: inherit;
    }
    .departamentosVillas .btnSelector{
        font-size: 30px;
    }
    .departamentosVillas .btnSelector.zoomed{
        transform: scale(1.5);
    }
    .tituloActividadesPorSemana{
        font-size: 21px;
    }
    .contenedorImgReporte{
        height: 330px;
    }
 }
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
        .imgError{
            width: 100%;
        }
   }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) { 
        .imgError{
            width: 90%;
        }
   }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
        .imgError{
            width: 80%;
        }

        .containerError{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
   }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 
        .imgError{
            width: 70%;
        }
   }
  
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) { 
        .imgError{
            width: 60%;
        }
   }
  
  /* XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) { 
        .imgError{
            width: 50%;
        }
   }

.proyecto .porcentaje{
	font-size: 14px;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
    width: fit-content;
	border: 1px solid #212529;
}

.imgModal{
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 

    .notienescuenta .logoGarteel{
        width: 60%;
    }

   }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) { 
    
    .notienescuenta .logoGarteel{
        width: 50%;
    }

   }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 

    .notienescuenta .logoGarteel{
        width: 45%;
    }

   }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 

    .notienescuenta .logoGarteel{
        width: 40%;
    }

   }
  
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) { 
    
    .notienescuenta .logoGarteel{
        width: 35%;
    }

   }
  
  /* XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) { 
    
   }


.tarjetaFase .porcentaje{
	font-size: 10px;
    color: #212529;
    background-color: white;
    border-radius: 5px;
    width: fit-content;
}

.tarjetaFase .imagenTarjeta{
    height: 200px;
   }

.tarjetasFases .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }

.tarjetasFases .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
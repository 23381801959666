table thead tr th:first-child {
	border-radius: 5px 0 0 0;
}

table thead tr th:last-child {
	border-radius: 0 5px 0 0;
}

table tbody tr:first-child td:first-child {
	border-radius: 5px 0 0 0;
}

table tbody tr:first-child td:last-child {
	border-radius: 0 5px 0 0;
}

table tbody tr:last-child td:first-child {
	border-radius: 0 0 0 5px;
}

table tbody tr:last-child td:last-child {
	border-radius: 0 0 5px 0;
}

table tbody tr:last-child th:first-child {
	border-radius: 0 0 0 5px;
}

table tbody tr:last-child th:last-child {
	border-radius: 0 0 5px 0;
}

table tbody tr:last-child{
    border-bottom: none;
}

.tablaProyectos .porcentaje{
	font-size: 10px;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
    width:fit-content;
	border: 1px solid rgb(166, 166, 166);
}

td{
	font-size: 10px;
}

th{
	font-size: 10px;
}

.tdNombreProyecto{
	width: 20%;
}

.tdProgreso{
	width: 1%;
}

@media (min-width: 576px) { 
	td{
		font-size: 14px;
	}
	
	th{
		font-size: 14px;
	}

	.tdNombreProyecto{
		width: 20%;
	}
	
	.tdProgreso{
		width: 30%;
	}
 }
.form-control:focus {
    border-color: gray;
    box-shadow: inset 0 1px 1px rgb(214, 214, 214), 0 0 8px rgb(214, 214, 214);
  }

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@font-face {
	font-family: Julius;
	src: url(./fonts/JuliusSansOne-Regular.ttf);
}

.float{
	position:fixed;
	width:50px;
	height:50px;
	bottom:10px;
	right:20px;
	background-color:#212529;
	border: 2px solid white;
	color:#FFF;
	border-radius:50px;
	display: flex;
	justify-content: center;
 	 font-size:30px;
	box-shadow: 2px 2px 3px #999;
  	z-index:100;
}
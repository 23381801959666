body {
    height: 100vh;
}

#root, .app {
    height: 100%;
}

.app {
    display: flex;
    position: relative;
}

.app main {
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.btnToggle{
    width: 50px;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 

   }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) { 

   }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 

    .btnToggle{
        display: none;
    }

   }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 

   }
  
  /* X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) { 

   }
  
  /* XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) { 
    
   }